.dashboard__counters {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  margin-bottom: 20px;

  & > div {
    box-sizing: border-box;
    flex: 0 0 33.333%;
    padding: 0 5px;
    margin-bottom: 10px;

    & > div,
    & > a {
      padding: 20px;
      background: lighten($ui-base-color, 4%);
      border-radius: 4px;
      border: 1px solid var(--background-border-color);
      box-sizing: border-box;
      height: 100%;
    }

    & > a {
      text-decoration: none;
      color: inherit;
      display: block;

      &:hover,
      &:focus,
      &:active {
        background: lighten($ui-base-color, 8%);
      }
    }
  }

  &__num,
  &__text {
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    color: $primary-text-color;
    margin-bottom: 20px;
    line-height: 30px;
  }

  &__text {
    font-size: 18px;
  }

  &__label {
    font-size: 14px;
    color: $darker-text-color;
    text-align: center;
    font-weight: 500;
  }
}

.dashboard {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: 10px;

  @media screen and (width <= 1350px) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }

  &__item {
    &--span-double-column {
      grid-column: span 2;
    }

    &--span-double-row {
      grid-row: span 2;
    }

    h4 {
      padding-top: 20px;
    }
  }

  &__quick-access {
    display: flex;
    align-items: baseline;
    border-radius: 4px;
    background: $ui-highlight-color;
    color: $primary-text-color;
    transition: all 100ms ease-in;
    font-size: 14px;
    padding: 8px 16px;
    text-decoration: none;
    margin-bottom: 4px;

    &:active,
    &:focus,
    &:hover {
      background-color: lighten($ui-highlight-color, 10%);
      transition: all 200ms ease-out;
    }

    &.positive {
      background: lighten($ui-base-color, 4%);
      color: $valid-value-color;
    }

    &.negative {
      background: lighten($ui-base-color, 4%);
      color: $error-value-color;
    }

    span {
      flex: 1 1 auto;
    }

    strong {
      font-weight: 700;
    }
  }
}
