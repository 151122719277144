.floating-action-button {
  display: none;
}

.navigation-panel_layout_top,
.navigation-panel_layout_bottom {
  @media screen and (width <= 630px) {
    .ui__header {
      display: flex;
      background: none;
      height: auto;
    }

    .ui__header__logo {
      display: none;
    }

    .columns-area__panels {
      min-height: 100vh;
      padding-bottom: 100px;
    }

    .tabs-bar__wrapper {
      top: 0;
    }

    /* Publish button */
    .ui__header__links {
      display: none;
    }

    /* Sidebar move from right to bottom */
    .columns-area__panels__main {
      width: 100%;
    }

    .columns-area__panels__pane--navigational {
      width: 100%;
      height: 60px;
      position: fixed;
      bottom: 0;
      left: 0;
    }

    .columns-area__panels__pane--navigational
      .columns-area__panels__pane__inner {
      width: 100%;
      height: 60px;
    }

    .columns-area__panels__pane--navigational .column-link {
      text-align: center;
      width: 36px;
    }

    .columns-area__panels__pane--navigational .navigation-panel {
      height: 60px;
      display: flex;
      flex-direction: row;
      padding: 0;
      scrollbar-width: none;
      background: lighten($ui-base-color, 13%);
    }

    .columns-area__panels__pane--navigational
      .navigation-panel::-webkit-scrollbar {
      display: none;
    }

    .columns-area__panels__pane--navigational
      .navigation-panel .navigation-panel__menu {
      display: flex;
    }

    .navigation-panel__menu a {
      flex: 0 0 auto;
    }

    .navigation-panel__legal hr {
      display: none;
    }

    .floating-action-button {
      position: fixed;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.9375rem;
      height: 3.9375rem;
      bottom: calc(env(safe-area-inset-bottom) + 1.3125rem + 48px);
      right: 1.3125rem;
      background: darken($ui-highlight-color, 2%);
      color: $white;
      border-radius: 50%;
      font-size: 21px;
      line-height: 21px;
      text-decoration: none;
      box-shadow: 2px 3px 9px rgba($base-shadow-color, 0.4);

      &:hover,
      &:focus,
      &:active {
        background: $ui-highlight-color;
      }
    }
  }
}

.navigation-panel_layout_top {
  @media screen and (width <= 630px) {
    .tabs-bar__wrapper {
      top: 50px;
    }

    .navigation-bar {
      margin-top: 50px;
    }

    .columns-area {
      margin-top: 50px;
    }

    .columns-area__panels__pane--navigational {
      height: 50px;
      top: 0;
      bottom: unset;
    }

    .columns-area__panels__pane--navigational
      .columns-area__panels__pane__inner {
      height: 50px;
    }

    .columns-area__panels__pane--navigational .navigation-panel {
      height: 50px;
    }

    .floating-action-button {
      bottom: 1.3125rem;
    }
  }
}

.fab_layout_left .floating-action-button {
  right: unset;
  left: 1.3125rem;
}
